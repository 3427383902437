import { useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import type { FormikErrors, FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useMediaQuery } from '@patrianna/shared-hooks'
import type { LoginFormValues } from '@patrianna/shared-patrianna-types/store/AuthModule'
import { getAuthFlowLoadingSelector } from '@patrianna/shared-store/auth'
import { useTranslation } from '@patrianna/core-components'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { login } from 'store/modules/auth/actions'
import Button from 'components/Button'
import FormPasswordField from 'components/Form/FormPasswordField'
import FormTextField from 'components/Form/FormTextField'
import classes from './styles.module.scss'
import validate from './validation'
import { useScheduledCallback } from 'app/hooks/useScheduledCallback'
import { useRouter } from 'app/context/navigation'
import Recaptcha from 'components/Recaptcha'
import { INPUT_PATTERNS } from 'src/components/Form/config'
import { getOneTimeSessionId } from 'services/mf/seon'

const initialValues: LoginFormValues = {
  email: '',
  password: '',
}

function LoginForm() {
  const router = useRouter()
  const query = useSearchParams()
  const [recaptchaToken, setRecaptchaToken] = useState<string>(null)
  const [shouldReset, setShouldReset] = useState(false)
  const [loginPassed, setLoginPassed] = useState(false)
  const pathname = usePathname()
  const isLoading = useAppSelector(getAuthFlowLoadingSelector)
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('xs')
  const t = useTranslation()
  const { data: seonSessionId, isLoading: isSeonSessionIdLoading } = useScheduledCallback(getOneTimeSessionId)

  const isLoginDisabled = isLoading || loginPassed || !recaptchaToken

  const handleReCaptcha = (token: string) => {
    setRecaptchaToken(token)
  }

  function handleLogin(values: LoginFormValues, { setSubmitting, setErrors }: FormikHelpers<LoginFormValues>) {
    setShouldReset(false)

    const onSetError = (err: FormikErrors<LoginFormValues>) => {
      setErrors(err)
    }

    const onErrorHandler = () => {
      setShouldReset(true)
    }

    dispatch(
      login(
        values,
        router.push,
        query,
        pathname,
        setSubmitting,
        onSetError,
        isMobile,
        seonSessionId,
        recaptchaToken,
        setLoginPassed,
        onErrorHandler
      )
    )
    router.refresh()
  }

  return (
    <Formik initialValues={initialValues} validate={(values) => validate(values)} onSubmit={handleLogin}>
      {({ isValid, isSubmitting }) => {
        return (
          <Form className={classes.root}>
            <FormTextField
              name='email'
              type='email'
              className={classes.input}
              placeholder={t('authPages.email_address')}
              pattern={INPUT_PATTERNS.NO_SPACES}
              aria-required={true}
            />

            <FormPasswordField
              name='password'
              className={classes.input}
              ignoreValidationDescription
              showValidationIcon={false}
            />

            <Recaptcha verifyCallback={handleReCaptcha} reset={shouldReset} />

            <Button
              type='submit'
              className={classes.button}
              disabled={!isValid || isSubmitting || isLoginDisabled}
              data-test='login-now-btn'
              loading={isSubmitting || isSeonSessionIdLoading}
            >
              {t('authPages.login')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
