import { Recaptcha as ReCAPTCHA } from '@patrianna/core-components'
import cx from 'classnames'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'
import { Checkbox } from '@patrianna/core-components/src/components/Checkbox'
import { useState } from 'react'
import useFakeCaptcha from 'components/Recaptcha/useFakeCaptcha'

type Props = {
  title?: string
  classes?: {
    title?: string
    captcha?: string
  }
  verifyCallback: (token: string) => void
  reset?: boolean
  resetOnExpired?: boolean
}

const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
const RECAPTCHA_BYPASS = process.env.RECAPTCHA_BYPASS

export default function Recaptcha({ verifyCallback, title, classes: classesProps, reset, resetOnExpired }: Props) {
  const t = useTranslation()
  const isFakeCaptcha = useFakeCaptcha()
  const [verified, setVerified] = useState(false)

  const fakeVerifyCallBack = () => {
    setVerified(true)
    verifyCallback(RECAPTCHA_BYPASS)
  }

  return (
    <>
      <p className={cx('mt-typography-body1', classesProps?.title)}>{title || t('PostalCodePage.step2_title')}</p>
      {isFakeCaptcha ? (
        <div data-test='fake-recaptcha' className={cx(classes.fakeRecaptcha, classesProps?.captcha)}>
          <Checkbox checked={verified} onChange={fakeVerifyCallBack} value={verified} />
          {"I'm not a robot (fake)"}
        </div>
      ) : (
        <ReCAPTCHA
          sitekey={RECAPTCHA_KEY}
          verifyCallback={verifyCallback}
          className={cx(classes.recaptcha, classesProps?.captcha)}
          reset={reset}
          resetOnExpired={resetOnExpired}
        />
      )}
    </>
  )
}
