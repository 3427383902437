'use client'

import { useMediaQuery } from '@patrianna/shared-hooks'
import { useAppDispatch } from 'src/store/hooks'
import { closeAllDialogs, openDialog } from 'store/modules/dialog/actions'
// import FacebookButton from 'src/containers/FacebookButton'
import GoogleAuthButton from 'src/containers/GoogleAuthButton'
import AuthDialogLayout from '../AuthDialogLayout'
import LoginDialogContent from './LoginDialogContent'
import ManualLogin from './ManualLogin'
import {
  // FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
} from 'src/config/apps'
import { useTranslation } from '@patrianna/core-components'

type Props = {
  loginWays?: 'social_only' | 'manual_only' | 'all'
}

export default function Login(props: Props) {
  const matches = useMediaQuery('xs')
  const dispatch = useAppDispatch()
  const t = useTranslation()

  const onForgotPassword = () => {
    if (!matches) {
      dispatch(closeAllDialogs())
    }
    dispatch(openDialog({ modalName: 'RESET_PASSWORD_DIALOG' }))
  }

  if (props.loginWays === 'manual_only') {
    return (
      <AuthDialogLayout dataTest='manual-login-dialog'>
        <ManualLogin onForgotPassword={onForgotPassword} />
      </AuthDialogLayout>
    )
  }
  if (props.loginWays === 'social_only') {
    return (
      <AuthDialogLayout dataTest='social-login-dialog'>
        {GOOGLE_APP_ID && <GoogleAuthButton authType='LOGIN' title={t('authPages.login_with_google')} />}
        {/*{FACEBOOK_APP_ID && <FacebookButton authType='LOGIN' text={t('authPages.login_with_facebook')} />}*/}
      </AuthDialogLayout>
    )
  }

  return (
    <AuthDialogLayout dataTest='login-dialog'>
      <LoginDialogContent />
    </AuthDialogLayout>
  )
}
