import cx from 'classnames'
import type { SocialAuthTypes } from '@patrianna/shared-patrianna-types/store/AuthModule'
import { useSocialButtonSpinner } from '@patrianna/shared-hooks'
import { getAuthFlowLoadingSelector } from '@patrianna/shared-store/auth'
import { isFacebookBrowser } from '@patrianna/shared-utils/helpers'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { openSnackbar } from 'store/modules/snackbar/actions'
import Button from 'components/Button'
import classes from './styles.module.scss'
import { googleAuth } from 'services/google/actions'
import { trackEvent } from 'config/analytic'

type Props = {
  title: string
  authType: SocialAuthTypes
}

function GoogleAuthButton(props: Props) {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(getAuthFlowLoadingSelector)
  const { isSendRequest, setIsSendRequest } = useSocialButtonSpinner(isLoading)

  const onClick = () => {
    const fbBrowser = isFacebookBrowser()

    if (fbBrowser) {
      dispatch(
        openSnackbar({
          message: `Please try to use a different sign-in method or visit ${process.env.ROOT_URL} from your web browser (Safari, Chrome etc)`,
        })
      )

      trackEvent(`${props.authType?.toLowerCase()}_google_auth_fb_iOS_error`, {
        category: `${props.authType}_google_auth_fb_iOS_error`,
        label: `${props.authType}_google_auth_fb_iOS_error`,
      })
    } else {
      setIsSendRequest(true)
      dispatch(googleAuth(props.authType))
    }
  }

  return (
    <Button
      disabled={isLoading}
      className={cx(classes.root, isSendRequest && classes.loading)}
      onClick={onClick}
      data-test={props.title}
      loading={isSendRequest && isLoading}
    >
      <img
        className={classes.icon}
        src={`${process.env.IMG_ORIGIN}/login/google.svg`}
        alt='google logo'
        aria-hidden={true}
      />
      <span className={classes.text}>{props.title}</span>
    </Button>
  )
}

export default GoogleAuthButton
