export const INPUT_PATTERNS = {
  NUMBERS_ONLY: '/^[0-9\b]+$/',
  LETTERS_ONLY: '/^[a-zA-Z\\s]+$/',
  LETTERS_AND_SINGLE_QUOTE: "/^[a-zA-Z\\s'\u2019]+$/",
  NO_SPACES: '/^\\S+$/',
  NUMBERS_LETTERS: '/^[a-zA-Z0-9]+$/',
}

export const months = [
  { value: '', name: 'Month' },
  { value: '1', name: 'January' },
  { value: '2', name: 'February' },
  { value: '3', name: 'March' },
  { value: '4', name: 'April' },
  { value: '5', name: 'May' },
  { value: '6', name: 'June' },
  { value: '7', name: 'July' },
  { value: '8', name: 'August' },
  { value: '9', name: 'September' },
  { value: '10', name: 'October' },
  { value: '11', name: 'November' },
  { value: '12', name: 'December' },
]
