import cx from 'classnames'
import classes from './styles.module.scss'

type Props = {
  className?: string
}

export default function LoginPromoteBanner(props: Props) {
  return (
    <div className={cx(classes.bannerTile, props.className)}>
      <img
        src={`${process.env.IMG_ORIGIN}/login/promo_banner2.png?v2`}
        alt='login promo banner'
        data-test='login-promo-banner'
        className={classes['bannerTile-image']}
      />
    </div>
  )
}
