import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import { Eye, EyeOff } from '@patrianna-icons/material-icons'
import classes from './styles.module.scss'
import { useTranslation } from '@patrianna/core-components'

type Props = {
  setTogglePass: (arg0: boolean) => void
  togglePass: boolean
  position?: 'start' | 'end'
  notOnlyAdornment?: boolean
}
export default function Adornment(props: Props) {
  const { togglePass, setTogglePass, notOnlyAdornment } = props
  const t = useTranslation()

  return (
    <IconButton
      className={cx(classes.passwordIcon, notOnlyAdornment && classes.notOnlyAdornment)}
      onClick={() => {
        setTogglePass(!togglePass)
      }}
      aria-label={t('common.toggle_password_visibility')}
      edge='end'
      type='button'
    >
      {togglePass ? <Eye /> : <EyeOff />}
    </IconButton>
  )
}
