import type { InputProps } from '@patrianna/core-components'
import { Input } from '@patrianna/core-components'
import type { FieldProps } from 'formik'
import { Field } from 'formik'
import { getRegExpFromString } from '@patrianna/shared-utils/helpers'
import ValidationAdornment from 'components/Form/FormPasswordField/ValidationAdornment'
import cx from 'classnames'
import classes from './styles.module.scss'

type FormTextFieldProps = {
  ignoreErrorMessage?: boolean
  iconValidation?: boolean
  forceTouched?: boolean
  isOverwritingStyles?: boolean
}

export default function FormTextField(props: FormTextFieldProps & InputProps) {
  const { ignoreErrorMessage, iconValidation, forceTouched, isOverwritingStyles, ...rest } = props

  return (
    <Field validateOnBlur validateOnChange name={props.name}>
      {({ field, form }: FieldProps) => {
        const error = form.errors[field.name]
        const touched = forceTouched !== undefined ? forceTouched && form.touched[field.name] : form.touched[field.name]
        const re = getRegExpFromString(props?.pattern)

        const defaultOnChange = (e: any) => {
          if (props?.pattern) {
            if (e.target.value === '' || re?.test(e.target.value)) {
              field.onChange(e)
            }
          } else {
            field.onChange(e)
          }
        }

        return (
          <Input
            {...rest}
            autoCorrect='off'
            autoCapitalize='none'
            value={field.value}
            onChange={props.onChange || defaultOnChange}
            onBlur={field.onBlur}
            className={cx(props.className, { [classes.root]: isOverwritingStyles })}
            placeholder={props.placeholder}
            // @ts-ignore
            error={error && touched}
            helperText={ignoreErrorMessage ? null : touched && error}
            startAdornment={props.startAdornment}
            endAdornment={iconValidation && touched && <ValidationAdornment hasError={!!error} />}
            aria-label={props.placeholder}
          />
        )
      }}
    </Field>
  )
}
