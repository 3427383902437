import cx from 'classnames'
import { useTranslation } from '@patrianna/core-components'
import classes from './../styles.module.scss'
import LoginForm from './LoginForm'

type LoginProps = {
  onForgotPassword: () => void
}

export default function ManualLogin(props: LoginProps) {
  const t = useTranslation()

  return (
    <>
      <LoginForm />

      <button
        className={cx('mt-typography-caption', classes.forgotPass)}
        onClick={props.onForgotPassword}
        data-test='forgot-pass-link'
      >
        {t('authPages.forgotPassword')}
      </button>
    </>
  )
}
