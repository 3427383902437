'use client'

import { useRouter } from 'app/context/navigation'
import { useEffect } from 'react'
import { useAppSelector } from 'src/store/hooks'
import { isLoggedInSelector, needToVerifyEmailSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

export default function AuthRedirect({ referrerUrl }: { referrerUrl?: string | string[] }): null {
  const loggedIn = useAppSelector(isLoggedInSelector)
  const emailNotVerified = useAppSelector(needToVerifyEmailSelector)
  const router = useRouter()

  useEffect(() => {
    if (loggedIn) {
      const referrer = Array.isArray(referrerUrl) ? referrerUrl[0] : referrerUrl
      router.push(emailNotVerified ? ROUTES.CONFIRM_EMAIL : referrer || ROUTES.HOME)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [loggedIn])

  return null
}
