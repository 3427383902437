import type { ReactNode } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'

type Props = {
  children: ReactNode
  className?: string
}
export default function AuthDivider(props: Props) {
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.text}>{props.children}</div>
    </div>
  )
}
