import { useState } from 'react'
import type { InputProps } from '@patrianna/core-components'
import { Input } from '@patrianna/core-components'
import type { FieldProps } from 'formik'
import { Field } from 'formik'
import ValidationAdornment from 'components/Form/FormPasswordField/ValidationAdornment'
import classes from './styles.module.scss'
import Adornment from './Adornment'
import { useTranslation } from '@patrianna/core-components'

type FormPasswordFieldProps = {
  ignoreErrorMessage?: boolean
  ignoreValidationDescription?: boolean
  showValidationIcon?: boolean
}

export default function FormPasswordField(props: FormPasswordFieldProps & InputProps) {
  const [togglePass, setTogglePass] = useState(false)
  const t = useTranslation()
  const { showValidationIcon = true } = props

  return (
    <Field validateOnBlur validateOnChange name={props.name}>
      {({ field, form }: FieldProps) => {
        const error = form.errors[field.name]
        const touched = form.touched[field.name]

        const hasError = touched && error
        const validationDescription = props.ignoreValidationDescription ? '' : t('common.passwordValidationDescription')
        const helperText = hasError ? error : validationDescription

        return (
          <Input
            error={!!(error && touched)}
            className={props.className}
            placeholder={props.placeholder || t('common.password')}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            id={props.name}
            name={props.name}
            type={togglePass ? 'text' : 'password'}
            endAdornment={
              <>
                <Adornment
                  togglePass={togglePass}
                  setTogglePass={setTogglePass}
                  notOnlyAdornment={!!touched && showValidationIcon}
                />
                {touched && showValidationIcon && <ValidationAdornment hasError={!!error} />}
              </>
            }
            data-test={props.name}
            helperText={props.ignoreErrorMessage ? null : helperText}
            classHelperText={!hasError ? classes.validationText : undefined}
            variant={props.variant}
            aria-label={props.placeholder || t('common.password')}
            aria-required={true}
          />
        )
      }}
    </Field>
  )
}
