import { Link } from 'components/Link'
// import { useIsFbBrowser } from '@patrianna/shared-hooks'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useAppDispatch } from 'src/store/hooks'
import { closeAllDialogs, replaceDialog } from 'store/modules/dialog/actions'
// import AppleAuthButton from 'src/containers/AppleAuthButton'
// import FacebookButton from 'src/containers/FacebookButton'
import GoogleAuthButton from 'src/containers/GoogleAuthButton'
import classes from './../styles.module.scss'
import ROUTES from 'temp/routes.json'
import ManualLogin from './ManualLogin'
import AuthDivider from 'containers/Auth/components/AuthDivider'
import LoginPromoteBanner from 'containers/Auth/components/LoginPromoteBanner'
import loginClasses from './styles.module.scss'
import {
  // FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
} from 'src/config/apps'
import { useTranslation } from '@patrianna/core-components'

function LoginDialogContent() {
  const matches = useMediaQuery('xs')
  const dispatch = useAppDispatch()
  const t = useTranslation()
  // const fbBrowser = useIsFbBrowser()

  const onForgotPassword = () => {
    if (!matches) {
      dispatch(closeAllDialogs())
    }
    dispatch(replaceDialog({ modalName: 'RESET_PASSWORD_DIALOG' }))
  }

  return (
    <div className={classes.loginContent}>
      <LoginPromoteBanner className={loginClasses.promoteBanner} />

      <ManualLogin onForgotPassword={onForgotPassword} />

      <AuthDivider>{t('authPages.or_login_with_social')}</AuthDivider>

      {GOOGLE_APP_ID && <GoogleAuthButton authType='LOGIN' title={t('authPages.login_with_google')} />}

      {/*{FACEBOOK_APP_ID && <FacebookButton authType='LOGIN' text={t('authPages.login_with_facebook')} />}*/}

      {/*{!fbBrowser && <AppleAuthButton title={t('authPages.login_with_apple')} authType='LOGIN' />}*/}

      <div className={classes.accountLink}>
        {t('authPages.dontHaveAccount')}
        <Link href={ROUTES.REGISTER}>
          <span className={classes.link} data-test='sign-up-link'>
            {t('authPages.signUp')}
          </span>
        </Link>
      </div>
    </div>
  )
}

export default LoginDialogContent
