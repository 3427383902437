import { useEffect, useRef, useState } from 'react'

type Callback = () => Promise<void> | Promise<string>

export function useScheduledCallback(callback: Callback, timeout = 5000) {
  const timeoutId = useRef(null)
  const requestIdleCallbackId = useRef(null)
  const executed = useRef(false)
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    function executeCallback() {
      if (executed.current) {
        return
      }

      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      if (requestIdleCallbackId.current) {
        window.cancelIdleCallback(requestIdleCallbackId.current)
      }

      if ('requestIdleCallback' in window) {
        requestIdleCallbackId.current = window.requestIdleCallback(() => {
          callback()
            .then((response) => {
              setData(response)
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              setIsLoading(false)
            })
        })
      } else {
        callback()
          .then((response) => {
            setData(response)
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }

      executed.current = true
    }

    // events to treck user activity
    const eventListeners = ['scroll', 'mousemove', 'onFocus']

    eventListeners.forEach((event) => {
      window.addEventListener(event, executeCallback, { once: true })
    })

    function cleanup() {
      eventListeners.forEach((event) => {
        window.removeEventListener(event, executeCallback)
      })

      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }

      if (requestIdleCallbackId.current) {
        window.cancelIdleCallback(requestIdleCallbackId.current)
      }
    }

    timeoutId.current = setTimeout(executeCallback, timeout)

    // Cleanup the event listeners and timer when the component unmounts
    return cleanup
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  return { data, isLoading }
}
