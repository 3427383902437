import type { ReactNode } from 'react'
import cx from 'classnames'
import classes from './styles.module.scss'

type Props = {
  children: ReactNode
  title?: string
  dataTest: string
}

const SCRATCHFUL = process.env.BRAND_NAME === 'scratchful'

export default function AuthDialogLayout(props: Props) {
  return (
    <div
      className={cx(classes.root, {
        [classes.contained]: SCRATCHFUL,
      })}
    >
      {props.children}
    </div>
  )
}
