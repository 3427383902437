import { useAppSelector } from 'store/hooks'
import { CAPTCHA_DISABLED, getItemFormLocalStorageSelector } from 'utils/localStorage'

const SHOW_FAKE_CAPTCHA = process.env.SHOW_FAKE_CAPTCHA

const useFakeCaptcha = () => {
  const captchaDisabledFlag = useAppSelector((state) => getItemFormLocalStorageSelector(state, CAPTCHA_DISABLED, false))
  const isCaptchaDisabled = process.env.PROFILE === 'dev' && captchaDisabledFlag
  const isFakeCaptcha = SHOW_FAKE_CAPTCHA || isCaptchaDisabled

  return isFakeCaptcha
}

export default useFakeCaptcha
